var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    {
      staticClass: "question-details",
      attrs: { "data-qa": "question-details" },
    },
    [
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        [_c("v-tab", [_vm._v("Question")]), _c("v-tab", [_vm._v("Answer")])],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        [
          _c(
            "v-tab-item",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("h3", [_vm._v("Description")]),
                  ]),
                  _c(
                    "v-col",
                    [
                      _c("quill-editor", {
                        attrs: {
                          disabled: true,
                          value: _vm.question.text,
                          options: { ..._vm.editorOptions },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.question.file_url
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "3" } }),
                      _c(
                        "v-col",
                        [
                          _c("m-img", {
                            attrs: {
                              src: _vm.question.file_url,
                              "max-width": "300",
                              "max-height": "300",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("h3", [_vm._v("Topics")]),
                  ]),
                  _c("v-col", [_c("p", [_vm._v(_vm._s(_vm.topics))])]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("h3", [_vm._v("Type")]),
                  ]),
                  _c("v-col", [_c("p", [_vm._v(_vm._s(_vm.qType))])]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("h3", [_vm._v("Difficulty")]),
                  ]),
                  _c("v-col", [
                    _c("p", [_vm._v(_vm._s(_vm.question.difficulty))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "answer-grid" },
              _vm._l(_vm.answers, function (answer, i) {
                return _c("div", { key: i, staticClass: "answer-item" }, [
                  _c(
                    "p",
                    {
                      staticClass: "answer__text",
                      class: { "green--text": answer.is_correct },
                      attrs: { "data-qa": "answer__text" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.question.type === "mcq" ? i + 1 + ". " : ""
                          ) +
                          " " +
                          _vm._s(_vm.format(answer.text)) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      answer.image
                        ? _c("m-img", {
                            attrs: {
                              src: answer.image.imageURL,
                              "max-width": "300",
                              "max-height": "300",
                              contain: "",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  answer.explanation
                    ? _c("div", [
                        _c("p", [
                          _c(
                            "span",
                            { staticClass: "font-weight-bold subtitle-2" },
                            [_vm._v("Explanation:")]
                          ),
                          _vm._v(" " + _vm._s(answer.explanation) + " "),
                        ]),
                      ])
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
      false
        ? _c("m-container", { staticClass: "py-0" }, [
            _c("h2", { staticClass: "mt-10" }, [_vm._v("Explanations")]),
            _vm.explanations.question
              ? _c("div", [
                  _c("p", [_vm._v(_vm._s(_vm.explanations.question.text))]),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _vm.explanations.question.image
                        ? _c("m-img", {
                            attrs: {
                              src: _vm.explanations.question.image.imageURL,
                              "max-width": "300",
                              "max-height": "300",
                              contain: "",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }