<template>
  <m-container class="question-details" data-qa="question-details">
    <v-tabs v-model="currentTab">
      <v-tab>Question</v-tab>
      <v-tab>Answer</v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <v-row>
          <v-col cols="3"><h3>Description</h3></v-col>
          <v-col
            ><quill-editor
              :disabled="true"
              :value="question.text"
              :options="{ ...editorOptions }"
            ></quill-editor
          ></v-col>
        </v-row>

        <v-row v-if="question.file_url">
          <v-col cols="3"></v-col>
          <v-col
            ><m-img
              :src="question.file_url"
              max-width="300"
              max-height="300"
            ></m-img
          ></v-col>
        </v-row>

        <v-row>
          <v-col cols="3"><h3>Topics</h3></v-col>
          <v-col
            ><p>{{ topics }}</p></v-col
          >
        </v-row>

        <v-row>
          <v-col cols="3"><h3>Type</h3></v-col>
          <v-col
            ><p>{{ qType }}</p></v-col
          >
        </v-row>

        <v-row>
          <v-col cols="3"><h3>Difficulty</h3></v-col>
          <v-col
            ><p>{{ question.difficulty }}</p></v-col
          >
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <div class="answer-grid">
          <div v-for="(answer, i) in answers" :key="i" class="answer-item">
            <p
              class="answer__text"
              data-qa="answer__text"
              :class="{ 'green--text': answer.is_correct }"
            >
              {{ question.type === "mcq" ? i + 1 + ". " : "" }}
              {{ format(answer.text) }}
            </p>
            <div class="d-flex justify-center">
              <m-img
                v-if="answer.image"
                :src="answer.image.imageURL"
                max-width="300"
                max-height="300"
                contain
              ></m-img>
            </div>
            <div v-if="answer.explanation">
              <p>
                <span class="font-weight-bold subtitle-2">Explanation:</span>
                {{ answer.explanation }}
              </p>
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <m-container v-if="false" class="py-0">
      <h2 class="mt-10">Explanations</h2>
      <div v-if="explanations.question">
        <p>{{ explanations.question.text }}</p>
        <div class="d-flex justify-center">
          <m-img
            v-if="explanations.question.image"
            :src="explanations.question.image.imageURL"
            max-width="300"
            max-height="300"
            contain
          ></m-img>
        </div>
      </div>
    </m-container>

    <!-- <div v-if="explanations.answers">
      <h3>Answer Explanations</h3>
      <div v-for="(a_explanation, i) in explanations.answers" :key="i">
        <template v-if="a_explanation.text || a_explanation.image">
          <p>{{ i + 1 }}. {{ a_explanation.text }}</p>
          <div class="d-flex justify-center">
            <m-img
              v-if="a_explanation.image"
              :src="a_explanation.image.imageURL"
              max-width="300"
              max-height="300"
              contain
            ></m-img>
          </div>
        </template>
      </div>
    </div> -->
  </m-container>
</template>

<script>
const math = /\$([^$]*)\$/g;

import katex from "katex/dist/katex.mjs";

export default {
  name: "QuestionDetails",
  props: {
    question: {
      type: Object
    },
    answers: {
      type: Array
    },
    explanations: {
      type: Object
    }
  },
  data() {
    return {
      currentTab: 0,
      editorOptions: {
        modules: {
          toolbar: false
        }
      }
    };
  },
  computed: {
    topics() {
      return this.question ? this.question.topics.join(", ") : "";
    },
    qType() {
      if (this.question.type === "mcq") return "Multiple Choice Question";
      else if (this.question.type === "fib")
        return "Fill in the Blank Question";
      else return "Descriptive Question";
    }
  },
  methods: {
    format(text) {
      if (text.includes("$")) {
        return text.replace(math, (match, txt) => {
          const rendered = katex.renderToString(txt, {
            displayMode: true,
            leqno: false,
            fleqn: false,
            throwOnError: false,
            errorColor: "#cc0000",
            strict: "warn",
            macros: { "\\f": "f(#1)" }
          });
          return rendered;
        });
      } else {
        return text;
      }
    }
  }
};
</script>

<style lang="scss">
.question-details {
  h2 {
    font-family: Roboto, serif;
    font-size: 22px;
    font-weight: 500;
    color: $text;
    margin-bottom: 10px;
  }
  h3 {
    font-family: Roboto, serif;
    font-size: 18px;
    font-weight: 500;
    color: $text;
    margin-bottom: 4px;
  }
  p {
    font-family: Roboto, serif;
    margin-bottom: 14px !important;
    color: #000000;
    text-align: justify;
  }
}
.answer-grid {
  display: grid;
  grid-template-columns: auto auto;
}
</style>
