import Vue from "vue";
import get from "lodash/get";

/**
 *
 * @param store
 * @returns {Vue|QuestionRepository}
 */
export function createQuestionRepository(store) {
  const NAMESPACE = "questionBank";
  const state = store.state[NAMESPACE];
  return new Vue({
    computed: {
      questions() {
        return state.questions;
      },
      essays() {
        return state.essays;
      }
    },
    methods: {
      _dispatch(name, payload) {
        return store.dispatch(`${NAMESPACE}/${name}`, payload);
      },
      _commit(name, payload) {
        return store.commit(`${NAMESPACE}/${name}`, payload);
      },
      list() {},

      get(questionId) {
        return this._dispatch("get", { questionId });
      },

      create(question) {
        return this._dispatch("create", { question });
      },

      update(questionId, questionData) {
        return this._dispatch("update", { questionId, questionData });
      },

      delete(questionId) {
        return this._dispatch("delete", { questionId });
      },

      createEssay(essay, courseId, ownerId) {
        return this._dispatch("createEssay", { essay, courseId, ownerId });
      },

      getEssay(essayId, courseId, ownerId, include = []) {
        return this._dispatch("getEssay", {
          essay_id: essayId,
          course_id: courseId,
          owner_id: ownerId,
          include
        });
      },

      createQuestionInfo(questions, answers, difficulty, type, subType, courseId, ownerId, essayId){
        return this._dispatch("createQuestionInfo", {questions, answers, difficulty, type, subType, courseId, ownerId, essayId});
      },

      getQuestionsInEssay(essayId) {},
      mapToLocal(loadedQuestion) {
        return {
          questionDetails: {
            text: loadedQuestion.text,
            topic: loadedQuestion.topic,
            difficulty: loadedQuestion.difficulty
          },
          answers: loadedQuestion.answers,
          explanations: { question: { text: loadedQuestion.explanation } },
          essay_id: loadedQuestion.essay_id,
          essay: loadedQuestion.essay
        };
      }
    }
  });
}

/**
 *
 * @param store
 * @returns {TopicRepository|Vue}
 */
export function createTopicRepository(store) {
  const NAMESPACE = "questionBank/topics";
  const state = get(store.state, NAMESPACE.replace("/", "."));
  return new Vue({
    computed: {
      topics() {
        return state.topics;
      }
    },
    methods: {
      _dispatch(name, payload) {
        store.dispatch(`${NAMESPACE}/${name}`, payload);
      },
      _commit(name, payload) {
        store.commit(`${NAMESPACE}/${name}`, payload);
      },
      async list() {
        await this._dispatch("list");
      },

      get(question_id) {},

      create(question_data) {},

      update(questionId, questionData) {},

      delete(question_id) {}
    }
  });
}
