var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: { "page-title": "Question Group Details" },
      }),
      _c(
        "lazy-load",
        { attrs: { item: _vm.essay } },
        [
          _vm.essay
            ? _c(
                "m-container",
                [
                  _c(
                    "m-card",
                    { staticClass: "pa-3" },
                    [
                      _c("m-card-text", [
                        _c(
                          "p",
                          { staticClass: "essay-text" },
                          [
                            _vm.essay.file_url
                              ? _c("m-img", {
                                  staticClass: "float-right ml-6",
                                  attrs: {
                                    src: _vm.essay.file_url,
                                    "max-width": "300",
                                    "max-height": "200",
                                    contain: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(_vm._s(_vm.essay.text) + " "),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "m-container",
            { staticClass: "question-table" },
            [
              _c(
                "m-card",
                [
                  _c(
                    "m-card-text",
                    [
                      _c("m-simple-table", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("SN")]),
                                    _c(
                                      "th",
                                      { staticStyle: { width: "70%" } },
                                      [_vm._v("Question")]
                                    ),
                                    _c("th", [_vm._v("Actions")]),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.essay.questions,
                                    function (question, i) {
                                      return _c("tr", { key: i }, [
                                        _c("td", [_vm._v(_vm._s(i + 1))]),
                                        _c(
                                          "td",
                                          [
                                            _c("quill-editor", {
                                              attrs: {
                                                disabled: true,
                                                value:
                                                  question.questionDetails.text,
                                                options: {
                                                  ..._vm.editorOptions,
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex" },
                                            [
                                              _c(
                                                "m-button",
                                                {
                                                  attrs: {
                                                    color: "orange",
                                                    outlined: "",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.viewQuestion(i)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("m-icon", [
                                                    _vm._v("remove_red_eye"),
                                                  ]),
                                                  _vm._v(" View"),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-app",
        [
          _c(
            "v-dialog",
            {
              model: {
                value: _vm.questionViewDialog,
                callback: function ($$v) {
                  _vm.questionViewDialog = $$v
                },
                expression: "questionViewDialog",
              },
            },
            [
              _c(
                "m-card",
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "15px",
                        right: "15px",
                      },
                    },
                    [
                      _c(
                        "m-icon",
                        {
                          on: {
                            click: function ($event) {
                              _vm.questionViewDialog = false
                            },
                          },
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "m-card-text",
                    [
                      _c("question-details", {
                        attrs: {
                          question: _vm.questionToView.questionDetails,
                          answers: _vm.questionToView.answers,
                          explanations: _vm.questionToView.explanations,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }