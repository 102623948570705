<template>
  <m-container>
    <page-title page-title="Question Group Details" class="mt-5">
      <!-- <template v-slot:action>
        <m-button :to="groupEditRoute" small outlined>
          <m-icon>edit</m-icon>
          Edit
        </m-button>
        <m-button
          color="red accent-3"
          class="ml-3"
          small
          dark
          outlined
          depressed
          :loading="deleting"
          @click="deleteGroup"
        >
          <m-icon>delete</m-icon>
          Delete
        </m-button>
      </template> -->
    </page-title>

    <lazy-load :item="essay">
      <m-container v-if="essay">
        <m-card class="pa-3">
          <m-card-text>
            <p class="essay-text">
              <m-img
                v-if="essay.file_url"
                class="float-right ml-6"
                :src="essay.file_url"
                max-width="300"
                max-height="200"
                contain
              ></m-img
              >{{ essay.text }}
            </p>
          </m-card-text>
        </m-card>
      </m-container>

      <m-container class="question-table">
        <m-card>
          <m-card-text>
            <m-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>SN</th>
                    <th style="width: 70%">Question</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(question, i) in essay.questions" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>
                      <quill-editor
                        :disabled="true"
                        :value="question.questionDetails.text"
                        :options="{
                          ...editorOptions
                        }"
                      />
                    </td>
                    <td>
                      <div class="d-flex">
                        <m-button
                          color="orange"
                          outlined
                          small
                          @click="viewQuestion(i)"
                          ><m-icon>remove_red_eye</m-icon> View</m-button
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </m-simple-table>
          </m-card-text>
        </m-card>
      </m-container>
    </lazy-load>

    <v-app>
      <v-dialog v-model="questionViewDialog">
        <m-card>
          <div style="position: absolute; top: 15px; right: 15px">
            <m-icon @click="questionViewDialog = false">close</m-icon>
          </div>
          <m-card-text>
            <question-details
              :question="questionToView.questionDetails"
              :answers="questionToView.answers"
              :explanations="questionToView.explanations"
            ></question-details>
          </m-card-text>
        </m-card>
      </v-dialog>
    </v-app>
  </m-container>
</template>

<script>
import { createQuestionRepository } from "#ecf/question-bank/store/types";
import QuestionDetails from "#ecf/question-bank/components/QuestionDetails";
import essayApi from "#ecf/question-bank/api/essay";

/**
 * @type {QuestionRepository}
 */
let questionRepository;

export default {
  name: "GroupQuestionDetailsPage",
  components: {
    QuestionDetails
  },
  props: {
    essayId: {
      type: String
    }
  },
  data: () => ({
    editorOptions: {
      modules: {
        toolbar: false
      }
    },
    questionToView: {},
    questionViewDialog: false,
    deleting: false
  }),
  computed: {
    essay() {
      return questionRepository.essays[this.essayId];
    },
    groupEditRoute() {
      return `/questions/groups/${this.essayId}/edit`;
    }
  },
  inject: ["$courseOwnerId", "$courseId"],
  async created() {
    questionRepository = createQuestionRepository(this.$store);
    await questionRepository.getEssay(
      this.essayId,
      this.$courseId(),
      this.$courseOwnerId(),
      ["questions"]
    );
  },
  methods: {
    viewQuestion(i) {
      this.questionToView = this.essay.questions[i];
      this.questionViewDialog = true;
    },
    async deleteGroup() {
      try {
        this.deleting = true;
        await essayApi.delete(
          this.essayId,
          this.$courseId(),
          this.$courseOwnerId()
        );
        await this.$router.replace({ name: "question-list" });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
      }
    }
  }
};
</script>

<style scoped>
.essay-text {
  text-align: justify;
  text-justify: inter-word;
  overflow: auto;
}
</style>
